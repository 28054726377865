
header.v-app-bar{
  /* app-barよりダイアログが上に来るように強制書き換え */
  z-index: 1000 !important;
}
.menu-links {
  width: 550px !important;
  right: 240px;
  top: 20px;
  z-index: 1007;

  .link-text{
    text-decoration: underline;
  }
}
.hide-overlay {
  .v-overlay__scrim {
    background-color: transparent;
  }
}
