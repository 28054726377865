
.header-bg {
  background-size: cover !important;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20id%3D%22header%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%201280%20100%22%3E%20%3Crect%20id%3D%22greenrect%22%20data-name%3D%22greenrect%22%20width%3D%221280%22%20height%3D%22100%22%20fill%3D%22%2391c761%22%2F%3E%20%3Cpath%20id%3D%22pinkpass%22%20data-name%3D%22pinkpass%22%20d%3D%22M681.355%2C0H1280V100H556.592Z%22%20fill%3D%22%23eba3a8%22%2F%3E%20%3C%2Fsvg%3E') !important;
  /* <svg id="header" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 100">
          <rect id="greenrect" data-name="greenrect" width="1280" height="100" fill="#91c761"/>
          <path id="pinkpass" data-name="pinkpass" d="M681.355,0H1280V100H556.592Z" fill="#eba3a8"/>
        </svg> */

}
.toolbar-title {
  color: white;
  font-size: 0.79rem;
}
.corporation-select.v-select {
  background-color: #fff;
  border-radius: 4px;
}
.corporation-chooser.vs__dropdown-menu {
  z-index: 1010;
  position: fixed;
}
.no-options {
  color: #ddd;
}
