ul.latest-medical-info{
  list-style: initial;
  padding-left: 40px !important;
}
body {
  background-color: #F8F8F8;
}
a {
  color: #604A49;
  text-decoration: none;
}
.notice-link {
  padding-bottom: 2px;
  border-bottom: 1px solid;
}
.v-card-text {
  /* --v-medium-emphasis-opacity が設定されるため強制解除 */
  opacity: initial !important;
}

.v-table {
  th, td{
    font-size: 1.125rem !important;
  }
  th {
    border-bottom: solid 1px white !important;
    border-right: solid 2px #F8F8F8 !important;
  }
  th:last-child {
    border-right: none !important;
  }
  td {
    border: solid 1px white !important;
  }
  td:first-child {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .w-15 {
    width: 15%
  }
  .w-25 {
    width: 25%
  }
  .w-20 {
    width: 20%
  }
  .w-40 {
    width: 40%
  }
  .w-45 {
    width: 45%
  }
  .w-50 {
    width: 50%
  }
  .w-75 {
    width: 75%
  }
}

.syounika_bg td {
  background-color: #FAEAEB !important;
}

.obstetrics_bg td {
  background-color: #EAF4E2 !important;
}

.task_status {
  th {
    background-color: #DDD !important;
  }
  td:first-child {
    border-left-color: #DDD !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  td {
    border-bottom-color: #DDD !important;
    border-right-color: #DDD !important;
    background-color: #FFF !important;
  }
}

.v-table tr:nth-child(even) td {
  background: #ededed;
}

.full-width {
  width: 100%;
}
.corporation-header {
  background-color: #F8F8F8;
}
/* 22px用のを共通で設定したほうが良さそう */
.corporation-header-title {
  font-size: 1.375rem;
}


/* vue3-datepicker */
.v3dp__datepicker {
  .v3dp__input_wrapper input {
    background: #fff;
    border-radius: 5px;
    width: 150px;
  }
}
.picker-align-right {
  .v3dp__popout{
    right: 0
  }
}

.mail-to {
  text-decoration: underline;
}
