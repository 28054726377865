
.side-nav {
  padding: 12px 0px 0px 12px;
  height: 90%;
}
.copyright {
  height: 10%;
  font-size: 0.79rem;
}
/** NOTE: 暫定対応 vuetify側の不具合が修正されたら対応をもとに戻す */
.list-home.v-list-item--active > .v-list-item__overlay {
  opacity: 0;
}
.list-home.home-active > .v-list-item__overlay {
  opacity: calc(0.12 * var(--v-theme-overlay-multiplier));
}
