
  .separator {
    border-top: 1px #604A49 solid;
    margin-top: 10px;
  }
  .main-counter-card {
    padding: 10px;
    color: #604A49;
  }
  .strong-bold {
    font-size: 1.7rem;
  }
