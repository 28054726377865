
.v-table.yearly-summary{
  th{
    font-size: 10px !important;
    font-weight: 700;
    padding: 0 5px !important;
  }
  td{
    font-size: 14px !important;
    padding: 0 10px !important;
  }
  tr{
    height: 40px;
  }
  tr.partition td {
    background-color: #604A49;
    color: #FFFFFF;
  }
  tr.bold{
    font-weight: 700;
  }
  tr.syounika td {
    background-color: #FFF2F3;
  }
  tr.obstetrics td {
    background-color: #F3FAEC;
  }
  tr.white td {
    background-color: #FFFFFF;
  }
  tr.yellow td {
    background-color: #FFF9E4;
  }
  th, td {
    border: 0 !important;
  }
}
